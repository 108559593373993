@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'RetroFunk';
  src: url('../assets/fonts/Retrofunkscriptpersonaluse-v6XO.otf');
}

@font-face {
  font-family: 'Brandon';
  src: url('../assets/fonts/BrandonText-Regular.otf');
}

@font-face {
  font-family: 'BrandonBold';
  src: url('../assets/fonts/BrandonText-Bold.otf');
}

@font-face {
  font-family: 'BrandonItalic';
  src: url('../assets/fonts/BrandonText-RegularItalic.otf');
}

div,
h2,
h3,
h4,
h5,
p,
span,
.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h,
.native-input.sc-ion-input-md,
.native-input.sc-ion-input-ios,
ion-alert,
ion-content,
ion-item,
ion-label,
ion-card,
ion-card-header,
ion-card-title,
ion-card-subtitle,
ion-card-content,
ion-grid,
ion-row,
ion-col,
ion-searchbar,
ion-toolbar,
ion-segment,
ion-segment-option,
ion-select,
ion-select-option,
ion-button,
ion-item-divider,
ion-list,
ion-list-header,
ion-text,
ion-badge,
ion-chip,
ion-input,
ion-textarea {
  font-family: 'Brandon', sans-serif;
}
ion-item > ion-label {
  font-family: 'Brandon', sans-serif;
}

h1 {
  font-family: 'BrandonBold', sans-serif !important;
}

ion-popover {
  --background: rgba(0, 0, 0, 1) !important;
}

.desktop {
  margin: 0 10% !important;
  max-width: 80% !important;
}
