.container {
  padding: 5em;
}

.paragraph {
  color: 'white';
  font-family: 'Brandon';
  font-size: '1em';
  margin: 2em 0;

}

.white {
  color: 'white';
  font-family: 'Brandon';
  font-size: '1em';
}

.bold {
  color: 'white';
    font-family: 'BrandonBold';
    font-size: '1em';
}